<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/elevator-model/page"
      @selection-change="handleSelectionChange">
      <el-button
        v-if="$auth('型号新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>
      <el-button
        v-if="$auth('型号合并')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.mergePage.open(selectList)">
        {{$l("common.add", "合并")}}
      </el-button>

      <el-button
        v-if="$auth('型号导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.name','电梯型号')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.name','电梯品牌')">
            <el-input v-model.trim="filter.brandName" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="name" :label="$l('user.name','电梯型号')" align="center"></el-table-column>
      <el-table-column prop="brandName" :label="$l('user.name','电梯品牌')" align="center"></el-table-column>
      <el-table-column prop="categoryDesc" label="电梯类别" align="center"></el-table-column>
      <el-table-column prop="varietyDesc" label="电梯品种" align="center"></el-table-column>
      <el-table-column prop="controlSystemDesc" label="控制系统" align="center"></el-table-column>
      <el-table-column prop="drivingModeDesc" label="驱动方式" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('型号编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('型号删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <merge-page ref="mergePage" @save-success="getList(-1)"></merge-page>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/model.xlsx"
        action="basic/elevator-model/import"
        name="电梯型号导入"
        download-name="电梯型号模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./ModalEdit";
  import MergePage from "./ModalMerge";
  export default {

    components: {EditPage,MergePage},
    data() {
      return {
        loading:false,
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",
        },
        selectList:[],
        visible: false,
      };
    },
    mounted() {
      this.getList(1);
      this.visible = false;
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },

      toggleStatus(row) {
        let statusDesc = row.status === "enabled" ? "禁用" : "启用";
        this.$confirm(`确定${statusDesc + row.phone}吗?`, "提示", {type: "warning"}).then(() => {
          this.$http.put(`users/${row.id}/toggleStatus`).then(() => {
            this.getList(-1);
          });
        });
      },
      resetPassword(row) {
        this.$prompt("请输入新密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: this.$rule.password.pattern,
          inputErrorMessage: "6~20位,不能为纯数字或者纯字母",
        }).then(({value}) => {
          this.$http.put(`users/${row.id}/resetPassword?newPassword=${value}`).then(() => {
            this.$message.success("重置密码成功");
          });
        });
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basic/elevator-model/", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    
    },
  };
</script>
<style lang="scss" scoped>
</style>
