<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/elevator-brand/page"
      @selection-change="handleSelectionChange"
    >
      <el-button
        v-if="$auth('品牌新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>
      <el-button
        v-if="$auth('品牌合并')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.mergePage.open(selectList)">
        {{$l("common.add", "合并")}}
      </el-button>

      <el-button
        v-if="$auth('品牌导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.name','品牌名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.type','厂家名称')">
            <el-input v-model.trim="filter.manufacturerName" type="userType"></el-input>
          </vm-search>
        </div>
      </template>
      <!--      <el-table-column align="center" type="index" width="50"></el-table-column>-->
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>

      <el-table-column prop="name" :label="$l('user.name','品牌名称')" align="center"></el-table-column>
      <el-table-column prop="manufacturerName" label="厂家名称" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('品牌编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('品牌删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <merge-page ref="mergePage" @save-success="getList(-1)"></merge-page>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/brand.xlsx"
        action="basic/elevator-brand/import"
        name="电梯品牌导入"
        download-name="电梯品牌模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./BrandEdit";
  import MergePage from "./BrandMerge";

  export default {

    components: {EditPage,MergePage},
    data() {
      return {
        loading:false,
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",

        },
        selectList:[],
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      toggleStatus(row) {
        let statusDesc = row.status === "enabled" ? "禁用" : "启用";
        this.$confirm(`确定${statusDesc + row.phone}吗?`, "提示", {type: "warning"}).then(() => {
          this.$http.put(`users/${row.id}/toggleStatus`).then(() => {
            this.getList(-1);
          });
        });
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basic/elevator-brand/", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    
    },
  };
</script>
<style lang="scss" scoped>
</style>
