<template>
  <el-tabs value="first" @tab-click="handleClick">
    <el-tab-pane v-if="$auth('电梯制造商/品牌/型号制造商')" label="制造商" name="first"><manufacturer></manufacturer></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯制造商/品牌/型号品牌')" label="品牌" name="second"><brand></brand></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯制造商/品牌/型号型号')" label="型号" name="third"><modal></modal></el-tab-pane>
  </el-tabs>
</template>

<script>
  import Manufacturer from "@/views/basic/Manufacturer";
  import brand from "./Brand";
  import  modal from "./Modal";
  export default {
    components:{brand,modal,Manufacturer},
  };
</script>

<style scoped>

</style>