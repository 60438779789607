<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑品牌':'新增品牌'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="品牌名称" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item :label="$l('project.name','厂家名称')" prop="manufacturerId">
        <vm-select-input :value="model.manufacturerName" @select="$refs.projectSelect.open()"></vm-select-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
    <manufacturer-select ref="projectSelect" @select="projectSelect"></manufacturer-select>
  </el-dialog>
</template>
<script>
  import ManufacturerSelect from "./ManufacturerSelect";

  export default {
    components: {ManufacturerSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          no: "",
          source: "",
          isp: "",
          iccid: "",
        },

      };

    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`/basic/elevator-brand/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("/basic/elevator-brand", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      projectSelect(row) {
        this.$set(this.model,"manufacturerId",row.id);
        this.$set(this.model,"manufacturerName",row.name);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
